import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './index.module.scss'
import Button from '../buttons/Button'

interface Props {
  onDeleteAccount: () => void
  onCancel: () => void
}
const DeleteAccount = ({ onDeleteAccount, onCancel }: Props) => (
  <div>
    <div className={styles.delete_acc_container}>
      <p>
        <FormattedMessage id="mis-datos.confirmar_borrar_cuenta" />
      </p>
      <div className={styles.buttons_container}>
        <Button onClick={onCancel}>No</Button>
        <Button onClick={onDeleteAccount}>Si</Button>
      </div>
    </div>
  </div>
)

export default DeleteAccount
