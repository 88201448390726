import React from 'react'
import { IAddressData, IUserData } from '../../context/reducers/userReducer'
import { t } from '../../i18n'
import Error from '../error/Error'
import FormError from '../FormError/FormError'
import styles from './index.module.scss'
import DocumentInputRow from '../DocumentInputRow/DocumentInputRow'
import ValidateButton from '../DocumentInputRow/ValidateButton'
import { FormMethods } from '../../types/FormMethods'
import { IMisDatosFields } from '../../helpers/schemas/MisDatosSchema'

interface Props {
  userData: IUserData
  addressData: IAddressData
  openChangePasswordModal: () => void
  birthdayError: string
  showGenderInput: boolean
  setShowGenderInput: (toogle: boolean) => void
  userDataSection: React.RefObject<HTMLDivElement>
  addressSection: React.RefObject<HTMLDivElement>
  onChangeCP: () => void
  form: FormMethods<IMisDatosFields>
}
export default function MisDatosForm({
  userData,
  addressData,
  openChangePasswordModal,
  birthdayError,
  userDataSection,
  addressSection,
  onChangeCP,
  form,
}: Props) {
  const { register, errors, watch } = form

  const fullBirthday = () => {
    const day = watch('birthday_day')
    const month = watch('birthday_month')
    const year = watch('birthday_year')
    if (!day && !month && !year) {
      return ''
    }
    if (day === '' && month === '' && year === '') {
      return ''
    }
    return `${day}-${month}-${year}`
  }

  return (
    <React.Fragment>
      <div className={styles.personal_data}>
        {t('mis-datos.personales')}

        <div ref={userDataSection} className={styles.data_doc}>
          <label className={styles.section_data_title}>
            {t('mis-datos.docu')}:
          </label>
          <div className={styles.validate_wrapper}>
            <DocumentInputRow form={form} />
            <ValidateButton watch={form.watch} />
          </div>
        </div>

        <div className={styles.data_doc}>
          <label className={styles.section_data_title}>
            {t('mis-datos.nombre')}:
          </label>
          <input
            ref={register}
            defaultValue={userData && userData.name}
            name="name"
            className={styles.inputForm_med}
            type="text"></input>
          <FormError errors={errors} field="name" />
        </div>

        <div className={styles.data_doc}>
          <label className={styles.section_data_title}>
            {t('mis-datos.apellidos')}:
          </label>
          <input
            ref={register}
            defaultValue={userData && userData.surname}
            name="surname"
            className={styles.inputForm_big}
            type="text"></input>
          <FormError errors={errors} field="surname" />
        </div>

        <div className={styles.data_doc}>
          <label className={styles.section_data_title}>
            {t('mis-datos.cumple')}:
          </label>
          <div className={styles.cont_err}>
            <div className={styles.birthday_cont}>
              <div className={styles.birthday_form}>
                <input
                  ref={register}
                  name="birthday_day"
                  defaultValue={userData && userData.birthday_day}
                  className={
                    !errors.full_birthday
                      ? styles.inputForm_mini
                      : styles.inputForm_mini_error
                  }
                  type="text"
                  placeholder="DD"></input>
                <p className={styles.separator}>/</p>
                <input
                  ref={register}
                  name="birthday_month"
                  defaultValue={userData && userData.birthday_month}
                  className={
                    !errors.full_birthday
                      ? styles.inputForm_mini
                      : styles.inputForm_mini_error
                  }
                  type="text"
                  placeholder="MM"></input>
                <p className={styles.separator}>/</p>
                <input
                  ref={register}
                  name="birthday_year"
                  defaultValue={userData && userData.birthday_year}
                  className={
                    !errors.full_birthday
                      ? styles.inputForm_mini
                      : styles.inputForm_mini_error
                  }
                  type="text"
                  placeholder="YYYY"></input>
                <input
                  type="hidden"
                  ref={register}
                  value={fullBirthday()}
                  name="full_birthday"
                />
              </div>
              <div className={styles.birthday_present}>
                {t('mis-datos.regalo')}
              </div>
            </div>
            <FormError errors={errors} field="full_birthday" />
            {birthdayError && <Error message={birthdayError}></Error>}
          </div>
        </div>

        <div className={styles.data_doc}>
          <label className={styles.section_data_title_gen}>
            {t('mis-datos.genero')}:
          </label>
          <div className={styles.gender_radio_btns}>
            <label className={styles.radio_cont}>
              <input
                defaultChecked={userData && userData.gender === 'mujer'}
                className={styles.input_check}
                ref={register}
                name="gender"
                type="radio"
                value="mujer"
              />
              <span className={styles.checkmark}></span>
              <label className={styles.checkbox_text}>
                {t('mis-datos.mujer')}
              </label>
            </label>
            <label className={styles.radio_cont}>
              <input
                defaultChecked={userData && userData.gender === 'hombre'}
                className={styles.input_check}
                ref={register}
                name="gender"
                type="radio"
                value="hombre"
              />
              <span className={styles.checkmark}></span>
              <label className={styles.checkbox_text}>
                {t('mis-datos.hombre')}
              </label>
            </label>
            <label className={styles.radio_cont}>
              <input
                defaultChecked={userData && userData.gender === 'otro'}
                className={styles.input_check}
                ref={register}
                name="gender"
                type="radio"
                value="otro"
              />
              <span className={styles.checkmark}></span>
              <label className={styles.checkbox_text}>
                {t('mis-datos.otro')}
              </label>
              <input
                defaultValue={userData && userData.gender_input}
                className={styles.inputForm_small}
                ref={register}
                name="gender_input"
                type="text"></input>
            </label>
          </div>
        </div>
      </div>
      <div className={styles.personal_data}>
        {t('mis-datos.acceso')}

        <div className={styles.data_doc}>
          <label className={styles.section_data_title}>
            {t('mis-datos.email')}:
          </label>
          <div className={styles.cont_err}>
            <input
              defaultValue={userData && userData.email}
              ref={register}
              name="email"
              className={styles.inputForm_med}
              type="text"></input>
            <FormError errors={errors} field="email" />
          </div>
        </div>

        <div className={styles.data_doc}>
          <label className={styles.section_data_title}>
            {t('mis-datos.contraseña')}:
          </label>
          <div className={styles.validate} onClick={openChangePasswordModal}>
            {t('mis-datos.cambia')}
          </div>
        </div>
      </div>
      <div className={styles.personal_data}>
        {t('mis-datos.contacto')}

        <div className={styles.data_doc}>
          <label className={styles.section_data_title}>
            {t('mis-datos.tel')}:
          </label>
          <div className={styles.cont_err}>
            <input
              defaultValue={userData && userData.phone}
              ref={register}
              name="phone"
              className={styles.inputForm_med}
              type="text"></input>
            <FormError errors={errors} field="phone" />
          </div>
        </div>

        <div ref={addressSection} className={styles.data_doc}>
          <label className={styles.section_data_title}>
            {t('mis-datos.adress')}:
          </label>
          <div className={styles.adress_cont}>
            <div>
              <label className={styles.adress_text}>
                {t('mis-datos.calle')}
              </label>
              <input
                defaultValue={addressData && addressData.address}
                ref={register}
                name="address"
                className={styles.inputForm_med}
                type="text"></input>
            </div>
            <FormError errors={errors} field="address" />
            <div>
              <label className={styles.adress_text}>
                {t('mis-datos.numero')}
              </label>
              <div className={styles.cont_err}>
                <input
                  defaultValue={addressData && addressData.number}
                  ref={register}
                  name="number"
                  className={styles.inputForm_small}
                  type="text"></input>
              </div>
            </div>
            <div>
              <label className={styles.adress_text}>
                {t('mis-datos.piso')}
              </label>
              <div className={styles.cont_err}>
                <input
                  defaultValue={addressData && addressData.block}
                  ref={register}
                  name="block"
                  className={styles.inputForm_small}
                  type="text"></input>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.data_doc}>
          <label className={styles.section_data_title}>
            {t('mis-datos.poblacion')}:
          </label>
          <div className={styles.cont_err}>
            <input
              defaultValue={addressData && addressData.city}
              ref={register}
              name="city"
              className={styles.inputForm_med}
              type="text"></input>
            <FormError errors={errors} field="city" />
          </div>
        </div>

        <div className={styles.data_doc}>
          <label className={styles.section_data_title}>
            {t('mis-datos.codigo-postal')}:
          </label>
          <div className={styles.cont_err}>
            <input
              defaultValue={userData && userData.postCode}
              ref={register}
              name="postCode"
              className={styles.inputForm_small}
              type="text"
              onChange={onChangeCP}
            />
            <FormError errors={errors} field="postCode" />
          </div>
        </div>

        <div className={styles.data_doc}>
          <label className={styles.section_data_title}>
            {t('mis-datos.provincia')}:
          </label>
          <input
            name="province"
            ref={register}
            className={styles.inputForm_med}
            readOnly
            defaultValue={addressData && addressData.province}
          />
        </div>
      </div>
      <div className={styles.personal_data}>
        {t('mis-datos.promo')}
        <div className={styles.data_doc_promo}>
          <label className={styles.section_data_title}> </label>
          <label className={styles.container2}>
            <input
              defaultChecked={userData && userData.promo_email === 1}
              className={styles.input_check}
              ref={register}
              type="checkbox"
              name={'promo_email'}
              value={'promo_email'}
            />
            <span className={styles.checkmark}></span>
            <label className={styles.checkbox_text}>
              {t('mis-datos.por_email')}
            </label>
          </label>
          <label className={styles.container2}>
            <input
              defaultChecked={userData && userData.promo_sms === 1}
              className={styles.input_check}
              ref={register}
              type="checkbox"
              name={'promo_sms'}
              value={'promo_sms'}
            />
            <span className={styles.checkmark}></span>
            <label className={styles.checkbox_text}>
              {t('mis-datos.por_sms')}
            </label>
          </label>
        </div>
      </div>
      <div className={styles.button_cont}>
        <button className={styles.continue_button} type="submit">
          {t('mis-datos.buscar')}
        </button>
      </div>
    </React.Fragment>
  )
}
