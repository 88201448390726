import * as yup from 'yup'
import { t } from '../../i18n'
import { DocumentData, DocumentSchema } from './DocumentSchema'
import { ValidateCP } from '../../../specific/helpers/validation/validationCP'
import { validatePhone } from '../../../specific/helpers/validation/validationPhone'
import { IUserData } from '../../context/reducers/userReducer'

export interface IMisDatosFields {
  document: DocumentData
  name: string
  surname: string
  full_birthday: string
  email: string
  phone: string
  address: string
  city: string
  province: string
  postCode: string
}

const MisDatosFields = {
  document: DocumentSchema,
  name: yup.string(),
  surname: yup.string(),
  full_birthday: yup
    .string()
    .matches(
      /(((0[1-9]|[12][0-9]|3[01])([-.])(0[13578]|10|12)([-.])(\d{4}))|(([0][1-9]|[12][0-9]|30)([-.])(0[469]|11)([-.])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([-.])(02)([-.])(\d{4}))|((29)(\.|-|\/)(02)([-.])([02468][048]00))|((29)([-.])(02)([-.])([13579][26]00))|((29)([-.])(02)([-.])([0-9][0-9][0][48]))|((29)([-.])(02)([-.])([0-9][0-9][2468][048]))|((29)([-.])(02)([-.])([0-9][0-9][13579][26])))/,
      {
        message: t('validations.mis_datos.birthday'),
        excludeEmptyString: true,
      }
    ),
  email: yup
    .string()
    .email(t('validations.register.valid_email'))
    .required(
      t('validations.register.required', { label: t('formLabels.email') })
    ),
  phone: yup
    .string()
    .test('test-phone_number', '${message}', validatePhone)
    .required(
      t('validations.register.required', { label: t('formLabels.phone') })
    ),
  address: yup.string(),
  city: yup.string(),
  province: yup.string(),
  postCode: yup
    .string()
    .test(
      'test-codigopostal',
      t('validations.form_facturacion.postalCode.invalid'),
      (cp) => ValidateCP(cp)
    ),
}
export const MisDatosSchema = yup.object().shape(MisDatosFields)

export interface IChangePasswordFields {
  password: string
  newPassword: string
  confirmPassword: string
}
const ChangePasswordFields = {
  password: yup
    .string()
    .min(6, t('validations.register.passwordLength'))
    .required(
      t('validations.register.required', { label: t('formLabels.password') })
    ),
  newPassword: yup
    .string()
    .min(6, t('validations.register.passwordLength'))
    .required(
      t('validations.register.required', { label: t('formLabels.password') })
    ),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('newPassword'), null],
      t('validations.register.must_match_passwords')
    ),
}

export const ChangePasswordSchema = yup.object().shape(ChangePasswordFields)

const misDatosMapping = {
  address: 'calle' as string,
  birthday_day: 'fechaNacimientoDia' as string,
  birthday_month: 'fechaNacimientoMes' as string,
  birthday_year: 'fechaNacimientoAño' as string,
  full_birthday: 'fecha_nacimiento' as string,
  block: 'bloque' as string,
  city: 'poblacion' as string,
  documentValue: 'numero_documento' as string,
  documentType: 'tipo_documento' as string,
  documentCountry: 'pais_documento' as string,
  email: 'email' as string,
  gender: 'genero' as string,
  gender_input: 'genero_descripccion' as string,
  name: 'nombre' as string,
  number: 'numero' as string,
  phone: 'telefono' as string,
  postCode: 'codigo_postal' as string,
  promo_email: 'promo_email' as string,
  promo_sms: 'promo_sms' as string,
  province: 'provincia' as string,
  surname: 'apellidos' as string,
  pago: 'pago' as string,
}

export const renamedMisDatos = (data) =>
  Object.keys(data).reduce((acc, key) => {
    if (key === 'document') {
      return Object.keys(data[key]).reduce((a, k) => {
        return {
          ...a,
          [misDatosMapping[k]]: data.document[k],
        }
      }, acc)
    }

    return {
      ...acc,
      [misDatosMapping[key]]: data[key],
    }
  }, {}) as IUserData
