import React, { useContext, useState } from 'react'
import styles from './index.module.scss'
import { t } from '../../i18n'
import Swal from 'sweetalert2'
import { alertProp } from '../../utils/swal'
import { findClientByNIF } from '../../api/user'
import * as UserActions from '../../context/actions/userActions'
import AppContext from '../../context/context'
import StandardModal from '../modal/standardModal/StandardModal'
import { renamedMisDatos } from '../../helpers/schemas/MisDatosSchema'

interface Props {
  watch: (name?: string | string[]) => unknown
}
const ValidateButton: React.FC<Props> = ({ watch }) => {
  const [showChangeDniModal, setShowChangeDniModal] = useState(false)
  const [validateLoading, setValidateLoading] = useState(false)

  const { userInfo, dispatch } = useContext(AppContext)

  const dniField = watch('document.documentValue') as string
  const documentType = watch('document.documentType') as string
  const documentCountry = watch('document.documentCountry') as string
  const changeDni = () => {
    setShowChangeDniModal(true)
  }
  const validateDni = async () => {
    if (!dniField.trim().length || !documentType.trim().length) {
      return Swal.fire(
        alertProp({
          type: 'error',
          title: 'Oops...',
          text: t('validations.mis_datos.empty_dni'),
        })
      )
    }
    const { idUser } = userInfo
    try {
      setValidateLoading(true)
      const res = await findClientByNIF(
        dniField,
        documentType,
        documentCountry,
        idUser
      )
      dispatch(UserActions.storeUserData(UserActions.transformData(res)))
      setValidateLoading(false)
      return Swal.fire(
        alertProp({
          type: 'success',
          title: t('mis-datos.validate_dni_success', {
            docType:
              documentType ||
              (userInfo.userData ? userInfo.userData.documentType : 'DNI'),
          }),
          text: '',
        })
      ).then((result) => {
        if (result.value) {
          const data = watch()
          const renamedData = renamedMisDatos(data)
          dispatch(UserActions.setUserData(idUser, renamedData))
        }
      })
    } catch ({ message }) {
      setValidateLoading(false)
      if (message === 'DNI_USED') {
        return Swal.fire(
          alertProp({
            type: 'error',
            title: 'Oops...',
            text: t('registerForm.error_dni_existe'),
          })
        )
      }
      return Swal.fire(
        alertProp({
          type: 'error',
          title: 'Oops...',
          text: t('validations.mis_datos.dni_incorrecto'),
        })
      )
    }
  }

  const hasUsuarioNav = userInfo.userData
    ? !!userInfo.userData.usuario_nav
    : false

  if (validateLoading) {
    return (
      <span>
        {t('mis-datos.validando-dni', {
          docType: documentType,
        })}
      </span>
    )
  }

  return (
    <React.Fragment>
      <button
        type={'button'}
        className={styles.validate}
        onClick={() => (hasUsuarioNav ? changeDni() : validateDni())}>
        {hasUsuarioNav
          ? t('mis-datos.cambiar-dni', {
              docType: documentType,
            })
          : t('mis-datos.validar-dni', {
              docType: documentType,
            })}
      </button>
      <StandardModal
        modalClass={styles.cambiar_dni_modal}
        title={t('mis-datos.cambia_dni_titulo', {
          docType: userInfo.userData
            ? userInfo.userData.documentType || 'DNI'
            : 'DNI',
        })}
        closeModal={() => setShowChangeDniModal(false)}
        showModal={showChangeDniModal}>
        <p className={styles.text_content}>
          {t('mis-datos.cambia_dni_description', {
            docType: userInfo.userData
              ? userInfo.userData.documentType || 'DNI'
              : 'DNI',
          })}
        </p>
      </StandardModal>
    </React.Fragment>
  )
}

export default ValidateButton
