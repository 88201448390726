import { graphql } from 'gatsby'
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import useForm from 'react-hook-form'
import Swal from 'sweetalert2'
import * as api from '../../api/user'
import { updateUser } from '../../api/user'
import DeleteAccount from '../../components/deleteAccount/DeleteAccount'
import Layout from '../../components/Layout'
import AreaPrivadaLayout from '../../components/layouts/areaPrivadaLayout/AreaPrivadaLayout'
import ChangePasswordForm from '../../components/misDatos/ChangePasswordForm'
import MisDatosForm from '../../components/misDatos/MisDatosForm'
import StandardModal from '../../components/modal/standardModal/StandardModal'
import { SeoData } from '../../types/Seo'
import SpinnerWithText from '../../components/spinnerWithText/SpinnerWithText'
import codigosPostales from '../../../specific/constants/cp'
import * as UserActions from '../../context/actions/userActions'
import AppContext from '../../context/context'
import { IUserData } from '../../context/reducers/userReducer'
import { t } from '../../i18n'
import logic from '../../logic'
import route from '../../utils/route'
import { alertProp } from '../../utils/swal'
import styles from './index.module.scss'
import { ValidateCP } from '../../../specific/helpers/validation/validationCP'
import {
  ChangePasswordSchema,
  IChangePasswordFields,
  IMisDatosFields,
  MisDatosSchema,
  renamedMisDatos,
} from '../../helpers/schemas/MisDatosSchema'

interface IPasswordState {
  loading: boolean
  error: string | null
  success: boolean
}

const initialPasswordState: IPasswordState = {
  loading: false,
  error: null,
  success: false,
}

export function passwordReducer(state, action) {
  switch (action.type) {
    case 'SET_PASSWORD':
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      }
    case 'SET_PASSWORD_OK':
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
      }
    case 'SET_PASSWORD_FAILED':
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        success: false,
      }
    case 'CLEAR_SUCCESS':
      return {
        ...state,
        success: false,
      }
    default:
  }
}

function changePassword(idUser: string, newData: IUserData, dispatch: any) {
  dispatch({
    type: 'SET_PASSWORD',
  })

  updateUser(idUser, newData)
    .then(() => {
      dispatch({
        type: 'SET_PASSWORD_OK',
      })
    })
    .catch((err) => {
      dispatch({
        type: 'SET_PASSWORD_FAILED',
        payload: {
          error: err.message,
        },
      })
    })
}

export function clearPasswordSuccess() {
  return {
    type: 'CLEAR_SUCCESS',
  }
}

const Content: React.FC = () => {
  const [showSelectorModal, setShowSelectorModal] = useState(false)
  const { userInfo, dispatch } = useContext(AppContext)
  const [showGenderInput, setShowGenderInput] = useState(false)
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false)
  const formHook = useForm<IMisDatosFields>({
    mode: 'onSubmit',
    validationSchema: MisDatosSchema,
  })
  const {
    handleSubmit,
    errors,
    watch,
    setValue,
    setError,
    clearError,
    triggerValidation,
  } = formHook

  const userDataSection = useRef(null)
  const addressSection = useRef(null)
  const [passwordState, passwordDispatch] = useReducer(
    passwordReducer,
    initialPasswordState
  )
  const {
    register: passwordRegister,
    handleSubmit: passwordHandleSubmit,
    errors: passwordErrors,
    reset: passwordFormReset,
  } = useForm<IChangePasswordFields>({
    mode: 'onSubmit',
    validationSchema: ChangePasswordSchema,
  })

  const closeChangePasswordModal = useCallback(() => {
    setShowSelectorModal(false)
    passwordFormReset()
  }, [])

  const openChangePasswordModal = useCallback(() => {
    setShowSelectorModal(true)
  }, [])

  const logout = () => {
    dispatch(UserActions.logout())
  }

  useEffect(() => {
    dispatch(UserActions.getUserData())
    dispatch(UserActions.setUserErrorNull())
  }, [])

  useEffect(() => {
    if (userInfo.errors) {
      userInfo.errors.forEach((error) => {
        setError(error.field, error.type, t(error.message))
      })
    } else {
      clearError()
    }
  }, [userInfo.errors])

  const onSubmit = (data: IMisDatosFields) => {
    const { idUser } = userInfo
    // translate names to match database
    const renamedData = renamedMisDatos(data)
    dispatch(UserActions.setUserData(idUser, renamedData))
  }

  const onPasswordSubmit = (data) => {
    const { idUser } = userInfo
    changePassword(idUser, data, passwordDispatch)
  }

  if (passwordState.success) {
    passwordDispatch(clearPasswordSuccess())
    closeChangePasswordModal()
    Swal.fire(
      alertProp({
        type: 'success',
        title: 'Gracias!',
        text: t('mis-datos.actualizar_datos_succces'),
      })
    )
  }

  if (userInfo.updateSuccess) {
    dispatch(UserActions.clearSuccess())
    Swal.fire(
      alertProp({
        type: 'success',
        title: 'Gracias!',
        text: t('mis-datos.actualizar_datos_succces'),
      })
    )
  }

  const deleteAccount = async () => {
    const { idUser } = userInfo
    try {
      await logic.deleteAccount(idUser)
      return Swal.fire(
        alertProp({
          type: 'success',
          title: t('mis-datos.borrar_cuenta_success'),
          text: '',
        })
      ).then((result) => {
        if (result.value) {
          logout()
        }
      })
    } catch ({ message }) {
      return Swal.fire(
        alertProp({
          type: 'error',
          title: 'Oops...',
          text: t('mis-datos.borrar_cuenta_error'),
        })
      )
    }
  }

  const scrollToRef = (ref) => {
    window.scrollTo(0, ref.current.offsetTop)
  }

  const handleErrorScroll = () => {
    const userErrors = ['dni', 'name', 'surname', 'full_birthday', 'email']
    const addressErrors = ['phone', 'postCode', 'address', 'city', 'province']
    const formErrors = Object.keys(errors).map((item) => item)
    const hasUserError = formErrors.some((r) => userErrors.includes(r))
    const hasAddressError = formErrors.some((r) => addressErrors.includes(r))
    if (hasUserError) {
      scrollToRef(userDataSection)
    } else if (hasAddressError) {
      scrollToRef(addressSection)
    } else {
      window.scrollTo(0, 0)
    }
  }

  const onChangeCP = async () => {
    const postCode = watch('postCode', '') as string
    if (process.env.GATSBY_WEB === 'rodimotor' && ValidateCP(postCode)) {
      const distrito = await api.getDistritoFromCpPt(postCode)
      if (distrito) {
        setValue('province', distrito.region)
      } else {
        setValue('province', '-')
      }
    } else {
      const provinciaCP = codigosPostales.find((codigoPostal) =>
        postCode.startsWith(codigoPostal.cp)
      )
      if (provinciaCP) setValue('province', provinciaCP.provincia)
    }
  }

  return (
    <React.Fragment>
      {userInfo.error && (
        <div className={styles.main_content}>
          <p>{userInfo.error}</p>
        </div>
      )}
      {userInfo.loading ? <SpinnerWithText text="Cargando tus datos" /> : null}
      <div
        className={`${styles.main_content} ${
          userInfo.loading && styles.hidden
        }`}>
        <form
          onChange={(e) => {
            const target = e.target as HTMLInputElement
            // Revalidar cualquier campo cuando cambia su valor
            clearError(target.name as 'phone' | 'email')
            return triggerValidation({
              name: target.name as 'phone' | 'email',
            })
          }}
          onSubmit={(e) => {
            handleErrorScroll()
            handleSubmit(onSubmit)(e)
          }}>
          <MisDatosForm
            userData={userInfo.userData}
            addressData={userInfo.addressData}
            openChangePasswordModal={openChangePasswordModal}
            birthdayError={null}
            showGenderInput={showGenderInput}
            setShowGenderInput={setShowGenderInput}
            userDataSection={userDataSection}
            addressSection={addressSection}
            onChangeCP={onChangeCP}
            form={formHook}
          />
        </form>
        <StandardModal
          title="Cambiar contraseña"
          closeModal={closeChangePasswordModal}
          showModal={showSelectorModal}>
          <form onSubmit={passwordHandleSubmit(onPasswordSubmit)}>
            <ChangePasswordForm
              passwordRegister={passwordRegister}
              passwordErrors={passwordErrors}
              changePasswordError={passwordState.error}
              loading={passwordState.loading}
            />
          </form>
        </StandardModal>
      </div>
      <a
        onClick={() => setShowDeleteAccountModal(true)}
        className={styles.delete_data}
        href="#">
        {t('mis-datos.borrar_cuenta')}
      </a>
      <StandardModal
        title="Borrar Cuenta"
        closeModal={() => setShowDeleteAccountModal(false)}
        showModal={showDeleteAccountModal}>
        <DeleteAccount
          onDeleteAccount={deleteAccount}
          onCancel={() => setShowDeleteAccountModal(false)}
        />
      </StandardModal>
    </React.Fragment>
  )
}

interface Props {
  data: {
    seoData: SeoData
  }
}
export default function Index({ data }: Props) {
  return (
    <Layout
      requireLogin={true}
      selector={false}
      seoData={data.seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.mis_datos'),
        },
      ]}>
      <AreaPrivadaLayout title={t('area_privada.tabs.mis_datos')}>
        <Content />
      </AreaPrivadaLayout>
    </Layout>
  )
}

export const pageQuery = graphql`
  query misDatosQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
