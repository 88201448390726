import React from 'react'
import { FieldErrors } from 'react-hook-form/dist/types'
import { t } from '../../i18n'
import Error from '../error/Error'
import FormError from '../FormError/FormError'
import SpinnerWithText from '../spinnerWithText/SpinnerWithText'
import styles from './index.module.scss'
import { IChangePasswordFields } from '../../helpers/schemas/MisDatosSchema'

interface Props {
  passwordRegister: any
  passwordErrors: FieldErrors<IChangePasswordFields>
  changePasswordError: string
  loading: boolean
}
export default function ChangePasswordForm({
  passwordRegister,
  passwordErrors,
  changePasswordError,
  loading,
}: Props) {
  if (loading) {
    return <SpinnerWithText text={t('login.cargando')} />
  }
  return (
    <React.Fragment>
      <div className={styles.password_form}>
        <div className={styles.form_item}>
          <label className={styles.section_data_title}>
            {t('mis-datos.contra_actu')}:{' '}
          </label>
          <input
            ref={passwordRegister}
            className={styles.inputForm_med}
            name="password"
            type="password"></input>
        </div>
        {passwordErrors.password && (
          <FormError errors={passwordErrors} field="password" />
        )}
        <div className={styles.form_item}>
          <label className={styles.section_data_title}>
            {t('mis-datos.nueva')}:{' '}
          </label>
          <input
            ref={passwordRegister}
            className={styles.inputForm_med}
            name="newPassword"
            type="password"></input>
        </div>
        {passwordErrors.newPassword && (
          <FormError errors={passwordErrors} field="newPassword" />
        )}
        <div className={styles.form_item}>
          <label className={styles.section_data_title}>
            {t('mis-datos.repite')}:{' '}
          </label>
          <input
            ref={passwordRegister}
            className={styles.inputForm_med}
            name="confirmPassword"
            type="password"></input>
        </div>
        {passwordErrors.confirmPassword && (
          <FormError errors={passwordErrors} field="confirmPassword" />
        )}
        {changePasswordError && <Error message={changePasswordError}></Error>}
      </div>
      <div className={styles.button_pass}>
        <button className={styles.change_button} type="submit">
          {t('mis-datos.buscar')}
        </button>
      </div>
    </React.Fragment>
  )
}
